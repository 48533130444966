@import 'config/includes';

.wrapper {
  border-radius: 12px;
  border: solid 1px $grey400;
  background-color: $white;
  text-align: left;
  height: 22px;

  &.disabled {
    pointer-events: none;
    opacity: 0.3
  }
}

.name {
  @include fontSize13;
  color: $textColor;
  margin: 2px 8px;
}
