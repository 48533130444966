@import "../config/includes";

.footer {
  display: flex;
  border-top: 1px solid $grey200;
  justify-content: space-between;
}

.info {
  @include fontSize13;
  font-style: italic;
  color: $grey400;
  padding: 12px;
  flex: 1 1 auto;
}

.paginationLinks {
  padding: 12px;
  flex: 0 0 auto;
  display: flex;
}

.prevLink, .nextLink {
  position: relative;
  font-size: 13px;
  display: flex;
  align-items: center;

  &.active {
    color: $textColor;
    &:hover {
      color: $blue700;
      text-decoration: underline;
      @include pointer;
    }
  }

  &.nonActive {
    color: $grey400;
    pointer-events: none;
  }
}

.prevLink {
  padding-left: 20px;
}

.nextLink {
  margin-left: 12px;
  padding-right: 20px;
}

.prevIcon, .nextIcon {
  position: absolute;
  font-size: 20px;
  top: -2px;
}

.prevIcon {
  left: 0;
}

.nextIcon {
  right: 0;
}
