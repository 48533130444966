@import '../../config/colors';
@import '../../config/mixins';

.wrapper {
  border-radius: 12px;
  border: solid 1px $grey400;
  background-color: white;
  text-align: left;
  height: 22px;

  &.disabled {
    pointer-events: none;
    opacity: 0.3
  }
}

.item {
  min-width: 38px;
  padding-right: 8px;
  text-align: left;
}

.ellipsis {
  min-width: 0;
}

.name {
  @include ellipsis;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.38;
  color: $textColor;
  margin: 3px 8px;
}
