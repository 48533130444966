@import "config/includes";

.feedbackWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;
  border-radius: 8px;

  .iconWrapper {
    flex: 0 0 auto;
    width: 48px; // 20px for the icon itself, 48px as a padding to the text
    box-sizing: border-box;

    .icon {
      display: block;
      font-size: 24px;
      vertical-align: center;
    }
  }

  .contentWrapper {
    flex: 1 1 auto;
    color: $textColor;
    @include fontSize15;
    min-width: 0;
  }

  .linkWrapper {
    flex: 0 0 auto;
    padding-left: 48px;
  }

  .buttonWrapper {
    flex: 0 0 auto;
    padding-left: 48px;
  }

  &.small {
    padding: 8px;
    flex-wrap: wrap;

    .iconWrapper {
      display: none;
    }

    .linkWrapper {
      padding-left: 0;
    }
  }

  &.info {
    background-color: $blue100;
    border: 1px solid $primaryActionColor;

    .iconWrapper > .icon {
      color: $primaryActionColor;
    }
  }

  &.warning {
    background-color: $yellow50;
    border: 1px solid $faceliftOrange;

    .iconWrapper > .icon {
      color: $yellow600;
    }
  }

  &.error {
    background-color: $red50;
    border: 1px solid $red600;

    .iconWrapper > .icon {
      color: $red800;
    }
  }

  &.success {
    background-color: $green50;
    border: 1px solid $green700;

    .iconWrapper > .icon {
      color: $green900;
    }
  }
}

.list {
  padding-left: 16px;

  .listItem {

  }
}

.link {
  @include fontSize15;
  font-weight: 600;

  &.info {
    color: $blue800;

    &.disabled {
      color: $blue400;
    }
  }

  &.warning {
    color: $yellow600;

    &.disabled {
      color: $yellow300;
    }
  }

  &.error {
    color: $red800;

    &.disabled {
      color: $red300;
    }
  }

  &.success {
    color: $green900;

    &.disabled {
      color: $green400;
    }
  }

  &.disabled {
    cursor: default;
  }
}

.showMoreLessLink {
  margin-left: 10px;
}
