@import "config/includes";

.wrapper {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  min-width: 30px;

  &.centre {
    justify-content: center;
  }

  .networkIcon {
    flex: 0 0 auto;
    font-size: 24px;
    margin-right: 12px;
  }

  .metricName {
    color: $textColor;
    @include fontSize18;
    font-weight: 600;
  }

  .privateStatisticsIcon, .customMetricIcon, .editIcon {
    color: $grey500;
  }

  .ellipsisWrapper {
    min-width: 30px;
  }

  .icon {
    font-size: 24px;
    margin-left: 8px;
  }

  &.small {
    .metricName {
      @include fontSize18;
    }

    .icon {
      font-size: 24px;
    }
  }

  &.medium {
    .metricName {
      @include fontSize24;
    }

    .icon {
      font-size: 24px;
    }
  }

  &.large {
    .metricName {
      @include fontSize30;
      color: $midnight900;
    }

    .icon {
      font-size: 24px;
    }
  }
}

.notesTooltip {
  max-width: 350px;
  text-align: left;
}
