.appError {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.feedback {
  flex: 1 1 auto;
  min-width: 300px;
  max-width: 600px;
  padding: 30px;
}
