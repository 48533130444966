@import "config/includes";

.tooltipInner {
  @include fontSize13;
  color: $white;
  background-color: $grey600;
  padding: 5px 10px;
  text-align: center;
  border-radius: 8px;
}

.tooltipArrow,
.tooltipArrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

.tooltipArrow::before {
  content: '';
  transform: rotate(45deg);
  background: $grey600;
}

.tooltip[data-popper-placement^="top"] > .tooltipArrow {
  bottom: -4px;
}


.tooltip[data-popper-placement^="bottom"] > .tooltipArrow {
  top: -4px;
}

.tooltip[data-popper-placement^="right"] > .tooltipArrow {
  left: -4px;
}

.tooltip[data-popper-placement^="left"] > .tooltipArrow {
  right: -4px;
}

.disabledContainer {
  position: relative;
}

.disabledActor {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
