@import "../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  font-size: 16px;
  padding-right: 6px;
  color: $grey500;
}

.value {
  @include fontSize13;
}
