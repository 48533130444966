@import "../../config/includes";

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}

.profilePicture {
  height: 32px;
  width: 32px;
  background-size: cover;
  border-radius: 8px;
  flex: 0 0 auto;
}

.none {
  color: $grey400;
}

.name {
  @include fontSize15;
  padding-left: 12px;
  position: relative;
  top: 4px;

  &.withAppendix {
    top: -4px
  }
}

.withoutThumbnail {
  padding-left: 0!important;
}

.appendix {
  color: $grey400;
  @include fontSize13;
  padding-left: 12px;
  position: relative;
  top: -8px;
}

.left {
  flex: 1 1 auto;
  min-width: 0;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
}

.right {
  flex: 0 0 auto;
}

.networkIcon {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 1px; // this is to avoid overflowing to the right (the icon somehow does that)
}

.ellipsisWrapper {
  min-width: 0;
  height: 32px;
  position: relative;
}
