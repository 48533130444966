@import "_colors";

@mixin pointer {
  cursor: pointer;
}

@mixin zoom {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

@mixin spacer {
  margin: 12px 0 20px 0;
  border: 0;
  border-top: 1px solid $grey300;
}

@mixin spacerBig {
  margin: 24px 0;
  border: 0;
  border-top: 1px solid $grey300;
}

@mixin spacerNoMargin {
  border: 0;
  border-top: 1px solid $grey300;
}

@mixin ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

@mixin ellipsisWithLineClamp {
  display: -webkit-box;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
}

@mixin noDefaultAppearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

@mixin placeholderColor($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}

@mixin icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'quintly-malibu' !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
