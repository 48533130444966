@import "config/includes";

.wrapper {
  margin-bottom: 25px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;

    .text {
      @include fontSize15;
      color: $grey500;
      text-align: center;
      max-width: 575px;
    }

    .actions {
      margin-top: 20px;
    }
  }
}
