@import "../config/includes";

.imageWrapper {
  position: relative;
  width: 100%;
  max-width: 120px;
  height: 0;
  padding-bottom: min(120px, 100%);

  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
    object-position: center;
    background: $grey200;
    vertical-align: center;
  }
}
