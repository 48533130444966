@import "config/includes";

.listWrapper {
  flex: 1 1 auto;
  display: flex;
}

.list {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
  // Applying a negative margin here so we can apply a 4px margin top to each list item, this ensures space between
  // multiple rows of list items, having no space at the top of the first row.
  margin-top: -4px;
}

.listItemWrapper {
  // Allow each list item to shrink if there is not enough space
  flex: 0 1 auto;
  margin-right: 20px;
  margin-top: 4px;
}

// flex parent
.filterItemWrapper {
  display: flex;
  align-items: center;
}

// flex children
.icon {
  flex: 0 0 auto;
}

.networkButtonWrapper {
  flex: 0 0 auto;

  line-height: 1;
  // Use this trick to avoid extra pixels on top of the i-tag inside: https://stackoverflow.com/a/19212391
  font-size: 0;
}

.contentWrapper {
  // Allow the content to shrink if there is not enough space
  flex: 0 1 auto;
}

.partiallyAppliedTextWrapper {
  flex: 0 0 auto;
  margin-left: 4px;
}

// rules for fully, partial and not applied filters
.notApplied {
  color: $grey400;

  .contentWrapper {
    text-decoration: line-through;
  }

  .icon {
    color: $grey400 !important;
  }
}

.customBinding {
  .contentWrapper, .partiallyAppliedTextWrapper {
    font-weight: 600;
  }
}

.button {
  .icon {
    font-size: 16px;
    margin-right: 4px;
  }
}

// rules per layout
.small {
  .listItemWrapper {
    @include fontSize13;
    color: $grey500;
  }

  .icon {
    font-size: 16px;
    color: $grey500;
    margin-right: 4px;
  }

  .networkButtonWrapper {
    > i, > span {
      font-size: 16px;
      color: $grey500 !important;
      margin-right: 4px;

      > span[class^="path"]:before {
        color: $grey500;
      }
    }


    :global(.oiuhb-icon-youtube) {
      font-size: 24px;
    }

  }
}



.big {
  .listItemWrapper {
    @include fontSize15;
  }

  .icon {
    font-size: 24px;
    margin-right: 8px;
  }

  .networkButtonWrapper {
    > i, > span {
      font-size: 24px;
      margin-right: 8px;
    }
  }
}
