@import "quintlyColorPalette";

// White and black we still reference as variables to have a simple rule of no hex codes in any other stylesheet file
$white: #fff;
$black: #000;

// social network related colors
$facebook: #1877F2;
$facebookVerifiedColor: #3696ff;
//$twitter: #1DA1F2;
$twitter: #000000;
$youtube: #FF0000;
$linkedIn: #0077B5;
$instagram: #000000;
$snapchatShow: #000000;
$tiktok: #000000;
$customMetric: #4f6f81;
$threads: #000000;
$overall: #3FB5CF;
$googleHover: #4285F4;
$snapchatButton: #fffc00;
$tiktok: #000000;
