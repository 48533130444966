@font-face {
  font-family: 'quintly-malibu';
  src:
    url('fonts/quintly-malibu.ttf?4j2m60') format('truetype'),
    url('fonts/quintly-malibu.woff?4j2m60') format('woff'),
    url('fonts/quintly-malibu.svg?4j2m60#quintly-malibu') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="oiuhb-icon-"], [class*=" oiuhb-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'quintly-malibu' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.oiuhb-icon-tiktokdevs:before {
  content: "\e980";
}
.oiuhb-icon-threads:before {
  content: "\e981";
}
.oiuhb-icon-x:before {
  content: "\e97f";
}
.oiuhb-icon-meta:before {
  content: "\e97e";
}
.oiuhb-icon-ads:before {
  content: "\e966";
}
.oiuhb-icon-create:before {
  content: "\e963";
}
.oiuhb-icon-remove:before {
  content: "\e964";
}
.oiuhb-icon-add:before {
  content: "\e965";
}
.oiuhb-icon-copy:before {
  content: "\e962";
}
.oiuhb-icon-schedule:before {
  content: "\e961";
}
.oiuhb-icon-snapchat .path1:before {
  content: "\e943";
  color: rgb(255, 255, 255);
}
.oiuhb-icon-snapchat .path2:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.oiuhb-icon-minus:before {
  content: "\e97c";
}
.oiuhb-icon-api:before {
  content: "\e900";
}
.oiuhb-icon-table:before {
  content: "\e96c";
}
.oiuhb-icon-waterfall:before {
  content: "\e96d";
}
.oiuhb-icon-pie:before {
  content: "\e96e";
}
.oiuhb-icon-pyramid:before {
  content: "\e96f";
}
.oiuhb-icon-funnel:before {
  content: "\e970";
}
.oiuhb-icon-radar:before {
  content: "\e971";
}
.oiuhb-icon-scatter:before {
  content: "\e972";
}
.oiuhb-icon-bubble:before {
  content: "\e973";
}
.oiuhb-icon-map:before {
  content: "\e974";
}
.oiuhb-icon-area:before {
  content: "\e975";
}
.oiuhb-icon-gauge:before {
  content: "\e976";
}
.oiuhb-icon-bignumber:before {
  content: "\e977";
}
.oiuhb-icon-line:before {
  content: "\e978";
}
.oiuhb-icon-column:before {
  content: "\e979";
}
.oiuhb-icon-bar:before {
  content: "\e97a";
}
.oiuhb-icon-note:before {
  content: "\e96b";
}
.oiuhb-icon-unlock:before {
  content: "\e96a";
}
.oiuhb-icon-timezone:before {
  content: "\e969";
}
.oiuhb-icon-reset:before {
  content: "\e968";
}
.oiuhb-icon-upload:before {
  content: "\e967";
}
.oiuhb-icon-tiktok:before {
  content: "\e960";
}
.oiuhb-icon-viewers:before {
  content: "\e95f";
}
.oiuhb-icon-views:before {
  content: "\e95c";
}
.oiuhb-icon-retweets:before {
  content: "\e95d";
}
.oiuhb-icon-likes:before {
  content: "\e95e";
}
.oiuhb-icon-filters:before {
  content: "\e95b";
}
.oiuhb-icon-descending:before {
  content: "\e959";
}
.oiuhb-icon-ascending:before {
  content: "\e95a";
}
.oiuhb-icon-post:before {
  content: "\e955";
}
.oiuhb-icon-auto:before {
  content: "\e956";
}
.oiuhb-icon-shares:before {
  content: "\e957";
}
.oiuhb-icon-comment:before {
  content: "\e958";
}
.oiuhb-icon-tag:before {
  content: "\e954";
}
.oiuhb-icon-history:before {
  content: "\e953";
}
.oiuhb-icon-public:before {
  content: "\e94f";
}
.oiuhb-icon-anchor:before {
  content: "\e950";
}
.oiuhb-icon-data:before {
  content: "\e951";
}
.oiuhb-icon-integrations:before {
  content: "\e952";
}
.oiuhb-icon-forward:before {
  content: "\e94e";
}
.oiuhb-icon-back:before {
  content: "\e94d";
}
.oiuhb-icon-sidebar-collapse:before {
  content: "\e905";
}
.oiuhb-icon-sidebar-expand:before {
  content: "\e934";
}
.oiuhb-icon-alert:before {
  content: "\e94c";
}
.oiuhb-icon-link:before {
  content: "\e94b";
}
.oiuhb-icon-in-progress:before {
  content: "\e94a";
}
.oiuhb-icon-pending:before {
  content: "\e949";
}
.oiuhb-icon-facebook:before {
  content: "\e901";
}
.oiuhb-icon-instagram:before {
  content: "\e902";
}
.oiuhb-icon-twitter:before {
  content: "\e903";
}
.oiuhb-icon-youtube:before {
  content: "\e90a";
}
.oiuhb-icon-linkedin:before {
  content: "\e906";
}
.oiuhb-icon-overall:before {
  content: "\e907";
}
.oiuhb-icon-refresh:before {
  content: "\e904";
}
.oiuhb-icon-disconnected:before {
  content: "\e941";
}
.oiuhb-icon-notification-no:before {
  content: "\e947";
}
.oiuhb-icon-notification-yes:before {
  content: "\e948";
}
.oiuhb-icon-format:before {
  content: "\e93f";
}
.oiuhb-icon-rocket:before {
  content: "\e93e";
}
.oiuhb-icon-key:before {
  content: "\e909";
}
.oiuhb-icon-fave-filled:before {
  content: "\e945";
}
.oiuhb-icon-fave:before {
  content: "\e946";
}
.oiuhb-icon-community:before {
  content: "\e944";
}
.oiuhb-icon-academic:before {
  content: "\e942";
}
.oiuhb-icon-hamburger-menu:before {
  content: "\e940";
}
.oiuhb-icon-clear:before {
  content: "\e908";
}
.oiuhb-icon-unchecked:before {
  content: "\e938";
}
.oiuhb-icon-verified:before {
  content: "\e939";
}
.oiuhb-icon-add-picture:before {
  content: "\e93a";
}
.oiuhb-icon-company:before {
  content: "\e93b";
}
.oiuhb-icon-plus:before {
  content: "\e93c";
}
.oiuhb-icon-switch:before {
  content: "\e93d";
}
.oiuhb-icon-search:before {
  content: "\e90b";
}
.oiuhb-icon-zoom:before {
  content: "\e90c";
}
.oiuhb-icon-metrics:before {
  content: "\e90d";
}
.oiuhb-icon-dashboard:before {
  content: "\e90e";
}
.oiuhb-icon-profiles:before {
  content: "\e90f";
}
.oiuhb-icon-settings:before {
  content: "\e910";
}
.oiuhb-icon-metric-editor:before {
  content: "\e911";
}
.oiuhb-icon-show:before {
  content: "\e912";
}
.oiuhb-icon-hide:before {
  content: "\e913";
}
.oiuhb-icon-edit:before {
  content: "\e914";
}
.oiuhb-icon-lock:before {
  content: "\e915";
}
.oiuhb-icon-error:before {
  content: "\e916";
}
.oiuhb-icon-success:before {
  content: "\e917";
}
.oiuhb-icon-info:before {
  content: "\e918";
}
.oiuhb-icon-warning:before {
  content: "\e919";
}
.oiuhb-icon-help:before {
  content: "\e91a";
}
.oiuhb-icon-folder:before {
  content: "\e91b";
}
.oiuhb-icon-folder-filled:before {
  content: "\e91c";
}
.oiuhb-icon-grid:before {
  content: "\e91d";
}
.oiuhb-icon-list:before {
  content: "\e91e";
}
.oiuhb-icon-more:before {
  content: "\e91f";
}
.oiuhb-icon-chevron-small-up:before {
  content: "\e920";
}
.oiuhb-icon-chevron-small-right:before {
  content: "\e921";
}
.oiuhb-icon-chevron-small-down:before {
  content: "\e922";
}
.oiuhb-icon-chevron-small-left:before {
  content: "\e923";
}
.oiuhb-icon-chevron-big-up:before {
  content: "\e924";
}
.oiuhb-icon-chevron-big-right:before {
  content: "\e925";
}
.oiuhb-icon-chevron-big-down:before {
  content: "\e926";
}
.oiuhb-icon-chevron-big-left:before {
  content: "\e927";
}
.oiuhb-icon-check-mark-in-box:before {
  content: "\e928";
}
.oiuhb-icon-check-mark:before {
  content: "\e929";
}
.oiuhb-icon-add1:before {
  content: "\e92a";
}
.oiuhb-icon-close:before {
  content: "\e92b";
}
.oiuhb-icon-delete:before {
  content: "\e92c";
}
.oiuhb-icon-date-selector:before {
  content: "\e92d";
}
.oiuhb-icon-star:before {
  content: "\e92e";
}
.oiuhb-icon-avatar:before {
  content: "\e92f";
}
.oiuhb-icon-share:before {
  content: "\e930";
}
.oiuhb-icon-export:before {
  content: "\e931";
}
.oiuhb-icon-vist-page:before {
  content: "\e932";
}
.oiuhb-icon-expand:before {
  content: "\e933";
}
.oiuhb-icon-minimise:before {
  content: "\e935";
}
.oiuhb-icon-global:before {
  content: "\e936";
}
.oiuhb-icon-move:before {
  content: "\e937";
}
.oiuhb-icon-home:before {
  content: "\e97b";
}
