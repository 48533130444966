@mixin fontSize10 {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.0025em;
}

@mixin fontSize11 {
  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.0025em;
}

@mixin fontSize13 {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.0025em;
}

@mixin fontSize15 {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.009em;
}

@mixin fontSize18 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.014em;
}

@mixin fontSize24 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.019em;
}

@mixin fontSize30 {
  font-size: 30px;
  line-height: 44px;
  letter-spacing: -0.02em;
}

@mixin fontSize64 {
  font-size: 64px;
  line-height: 56px;
  letter-spacing: -0.04em;
}
