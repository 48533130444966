@import "../config/includes";

.head {
  margin-bottom: 2px;
}

.body {
  display: block;
}

.link {
  color: $blue700;
  cursor: pointer;
  margin-left: 12px;

  > a {
    color: $blue700;
  }
}

.content {
  // do not set font-size in here as this is done for the whole table and different per context (e.g. report vs tool)
}

.attachment {
  margin-top: 12px;
}

.image {
  width: 120px;
  height: 120px;
  object-fit: contain;
  object-position: center;
  background: $grey200;
}

.noContent {
  color: $grey400;
}
