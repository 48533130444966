@import "../config/includes";

.headerCell {
  position: relative;
  font-weight: 600;
  padding: 12px;

  &:hover {
    color: $blue700;
    @include pointer;
  }
}

.icon {
  position: absolute;
  right: 12px;
  top: 10px;
  font-size: 20px;
}

.activelySorted {

}
