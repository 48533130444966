@import "config/includes";

.facebookColor {
  color: $facebook;
}

.twitterColor {
  color: $twitter;
}

.youtubeColor {
  color: $youtube;
}

.linkedInColor {
  color: $linkedIn;
}

.instagramColor {
  color: $instagram;
}

.snapchatShowColor {
  color: $snapchatShow;
}

.tiktokColor {
  color: $snapchatShow;
}

.overallColor {
  color: $overall;
}

.customMetricColor {
  color: $customMetric;
}

.threadsColor {
  color: $threads;
}

.disabled {
  color: $grey300;
}

.meta {
  width: 24px;
  height: 24px;
}
