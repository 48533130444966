@import "../config/includes";

.green {
  color: $green900;
}

.red {
  color: $red700;
}

.notAvailable {
  color: $grey400;
}

.activelySorted {

}

.head {
  margin-bottom: 2px;
}

.body {
  display: block;
}

.bold {
  font-weight: 600;
}

.link {
  color: $blue700;
  cursor: pointer;
  margin-left: 12px;

  > a {
    color: $blue700;
  }
}

.noLinkPadding {
  margin-left: 0;
}

.flex {
  display: flex;
}

.attachmentText {
  margin-top: 5px;
  overflow: hidden;
}

.tableCell {
  padding: 12px;
}

.countryFlagIcon {
  width: 16px;
  height: 11px;
  margin-right: 5px;
  margin-top: 1px;
}

.attachment {
  margin-top: 12px;
}

.userPicture {
  display: block;
  width: 40px;
  max-height: 130px;
  margin-right: 12px;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @include fontSize13;
}

.rightItem {
  flex: 0 0 auto;

  &:first-child .link {
    margin-left: 0;
  }
}
