.cell {
  max-width: 0;
}

.columnFlexParent {
  display: flex;
  flex-direction: column;
}

.rowFlexParent {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.rowFlexChild {
  flex: 0 1 auto;
  min-width: 0;
}

.editButton {
  margin-top: 8px
}