@import "config/includes";


:global(.grid-hover-button-opacity) {
  &:global(.medium) {
    opacity: 0.5;
  }
  opacity: 0.2;
  transition: opacity 0.1s ease-in-out;
}

:global(.list-hover-button-opacity) {
  opacity: 0;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-weight: 600;
  border-radius: 8px;
  outline: none;
  padding: 0 18px 0 0;


  &.leftCurved {
    border-radius: 8px 0 0 8px!important;
  }

  &.rightCurved {
    border-radius: 0 8px 8px 0!important;
  }

  .loadingIndicator {
    position: absolute;
    left: 18px;
  }

  // shared styles across all styles/layouts
  .iconWrapper, .label, .multiActionIconWrapper {
    flex: 0 0 auto;
    display: flex;
    align-content: center;
    padding-left: 18px;

    > i {
      flex: 0 0 auto;
    }
  }

  .label {
    &.responsive {
      flex: 0 1 auto;
      min-width: 10px;
      display: flex;
    }
  }

  .iconWrapper {
    line-height: 1;
    margin-right: -12px; // we use negative margin technique to set specific margins for this element
    margin-left: -2px; // we use negative margin technique to set specific margins for this element
  }

  .icon {
    border: none !important;
  }

  .multiActionIconWrapper {
    margin-right: -8px; // we use negative margin technique to set specific margins for this element
    display: flex;
    align-items: center;

    .multiActionSpacer {
      flex: 0 0 auto;
      width: 1px;
      height: 16px;
      background-color: $grey400;
    }

    .multiActionIcon {
      flex: 0 0 auto;
      vertical-align: middle;
      padding-left: 10px;
    }

    &.multiActionDisabled {
      .multiActionSpacer {
        background-color: $grey300;
      }
    }
  }

  &.loading {
    &:not(.stretch) {
      > :nth-child(2) {
        transition: padding 150ms ease-in-out;
        padding-left: 52px; // 18px (padding) + 24px (loading icon width) + 10px (padding)
      }
    }
  }

  &:active, &.active {
    opacity: 1;
  }

  &.stretch {
    width: 100%;
    justify-content: center;
    &.loading {
      > * {
        position: relative;
        left: -12px;
      }
    }
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  // special rules for large buttons
  &.large {
    height: 40px;

    .iconWrapper {
      font-size: 24px;
    }

    .iconImage {
      width: 24px;
      height: 24px;
    }

    .label {
      @include fontSize15;
    }

    .multiActionIcon {
      font-size: 24px;
    }
  }

  // special rules for small buttons
  &.small {
    height: 32px;

    .iconWrapper {
      font-size: 18px;
    }

    .iconImage {
      width: 18px;
      height: 18px;
      padding-left: 8px;
    }

    .label {
      @include fontSize13;
    }

    .multiActionIcon {
      font-size: 20px;
    }
  }

  // special rules for "default" layout
  &.default {
    color: $textColor;
    background-color: $white;
    border: 1px solid $grey400;

    &:hover {
      @include pointer;
      color: $blue700;
      border: 1px solid $primaryActionColor;
      background-color: $blue50;

      // necessary to e.g. also color inner appendix span
      span {
        color: $blue700;
      }

      // drop-down in multi-action case
      &:not(.disabled):not(:disabled) {
        .multiActionSpacer {
          background-color: $primaryActionColor;
        }
      }
    }

    &:active, &.active {
      background-color: $blue100;

      &:hover {
        background-color: $blue200;
      }
    }

    &.disabled {
      color: $grey300;
      background-color: $white;
      border: 1px solid $grey300;

      &:hover {
        cursor: default;

        span {
          color: $grey300;
        }
      }
    }

    &:disabled {
      cursor: default;

      &:active, &.active {
        background-color: $white;
      }
    }

    &.loading {
      color: $blue700;
      background-color: $blue50;
      border: 1px solid $teal600;

      &:hover, &:active, &.active, &.disabled, &:disabled {
        color: $blue700;
        background-color: $blue50;
        border: 1px solid $primaryActionColor;

        // necessary to e.g. also color inner appendix span
        span {
          color: $blue700;
        }
      }

      // drop-down in multi-action case
      .multiActionSpacer {
        background-color: $teal600;
      }
    }

    &.favorite {
      border: solid 1px $red400;
      color: $red400;

      &:disabled {
        color: $red50;
        border-color: $red50;
        pointer-events: none;
      }

      &.active, &:hover {
        background-color: $white !important;
      }
    }

    &.facebook {
      color: $white;
      background-color: $facebook;
      border: 0;

      &:active, &.active {
        background-color: $facebook;
      }

      .icon {
        color: $white;
        margin-left: -8px;
      }

      &.disabled {
        background-color: $grey300;
      }

      &.loading {
        color: $white;
        background-color: $facebook;
      }

    }

    &.threads {
      color: $white;
      background-color: $threads;
      border: 0;
      &:active, &.active {
        background-color: $threads;
      }
      &:hover {
        span {
          color: $white;
        }
      }
      .icon {
        color: $white;
        margin-left: -8px;
      }
      &.disabled {
        background-color: $grey300;
      }
      &.loading {
        color: $white;
        background-color: $threads;
      }
    }

    &.youtube {
      color: $white;
      background-color: $youtube;
      border: 0;

      &:active, &.active {
        background-color: $youtube;
      }

      .icon {
        color: $white;
        margin-left: -8px;
      }

      &.disabled {
        background-color: $grey300;
      }

      &.loading {
        color: $white;
        background-color: $youtube;
      }
    }

    &.twitter {
      color: $white;
      background-color: $twitter;
      border: 0;

      &:active, &.active {
        background-color: $twitter;
      }

      .icon {
        color: $white;
        margin-left: -8px;
      }

      &.disabled {
        background-color: $grey300;
      }

      &.loading {
        color: $white;
        background-color: $twitter;
      }
    }

    &.google {
      color: $textColor;
      background-color: $white;

      &:hover {
        color: $googleHover;
        border: 1px solid $googleHover;
      }

      &:active, &.active {
        background-color: $white;
      }

      .icon {
        color: $white;
        margin-left: -8px;
      }

      &.disabled {
        background-color: $grey300;
      }
    }

    &.linkedIn {
      color: $white;
      background-color: $linkedIn;
      border: 0;

      &:active, &.active {
        background-color: $linkedIn;
      }

      .icon {
        color: $white;
        margin-left: -8px;
      }

      &.disabled {
        background-color: $grey300;
      }

      &.loading {
        color: $white;
        background-color: $linkedIn;
      }
    }

    &.snapchatShow {
      color: $snapchatShow;
      background-color: $snapchatButton;
      border: 1px solid $grey500;

      &:active, &.active {
        background-color: $snapchatButton;
      }

      .icon {
        color: $snapchatShow;
        margin-left: -8px;
      }

      &.disabled {
        background-color: $grey300;
        border: 1px solid $grey300;
      }

      &.loading {
        color: $snapchatShow;
        background-color: $snapchatButton;
        border: 1px solid $snapchatButton;
      }
    }

    &.tiktok {
      color: $white;
      background-color: $tiktok;
      border: 1px solid $tiktok;

      &:hover {
        border: 1px solid $tiktok;
      }

      &:active, &.active {
        background-color: $tiktok;
      }

      .icon {
        color: $white;
        margin-left: -8px;
      }

      &.disabled {
        background-color: $grey300;
      }

      &.loading {
        color: $white;
        background-color: $tiktok;
        border: 1px solid $tiktok;
      }
    }

    // additional rules if the button is a warning button
    &.warning {

      color: $red600;
      border: 1px solid $red600;
      background-color: $white;

      // necessary to e.g. also color inner appendix span
      span {
        color: $red600;
      }

      &:hover {

        background-color: $red50;
      }

      &:active, &.active {
        background-color: $red100;
      }

      &.disabled {
        color: $red100;
        background-color: $white;
        border: 1px solid $red300;

        &:hover {
          cursor: default;

          span {
            color: $grey300;
          }
        }
      }

      &:disabled {
        cursor: default;

        &:active, &.active {
          background-color: $white;
        }
      }

      &.loading {
        color: $red600;
        background-color: $red50;
        border: 1px solid $red600;

        &:hover, &:active, &.active, &.disabled, &:disabled {
          color: $red600;
          background-color: $red50;
          border: 1px solid $red600;

          // necessary to e.g. also color inner appendix span
          span {
            color: $red600;
          }
        }
      }
    }

    &.feedbackWarning {
      background-color: $faceliftOrange;
      border: 1px solid $yellow400;
      color: $white;

      &:hover {
        border: 1px solid $faceliftOrange;
        color: $yellow50;
        box-shadow: inset 0 -4px 0 0 $yellow600;

        // necessary to e.g. also color inner appendix span
        span {
          color: $yellow200;
        }
      }

      &:active, &.active {
        box-shadow: none;
        background-color: $yellow600;
      }


      &.disabled {
        color: $faceliftOrange;
        background-color: $yellow50;
        border: 1px solid $faceliftOrange;

        &:hover {
          cursor: default;

          span {
            color: $faceliftOrange;
          }
        }
      }

      &:disabled {
        cursor: default;

        &:active, &.active {
          background-color: $white;
        }
      }

      &.loading {
        color: $faceliftOrange;
        background-color: $yellow50;
        border: 1px solid $faceliftOrange;

        &:hover, &:active, &.active, &.disabled, &:disabled {
          color: $faceliftOrange;
          background-color: $yellow50;
          border: 1px solid $faceliftOrange;

          // necessary to e.g. also color inner appendix span
          span {
            color: $faceliftOrange;
          }
        }
      }
    }
  }

  // special rules for "action" layout
  &.action {
    color: $white;
    background-color: $primaryActionColor;
    border: none;

    &:hover {
      background-color: $blue700;
      box-shadow: inset 0 -4px 0 0 $blue800;
      @include pointer;
    }

    &:active, &.active {
      background-color: $blue800;
      box-shadow: none;
      color: $blue100;
    }

    &.disabled {
      background-color: $blue300 !important;
      color: $white !important;
      box-shadow: none !important;

      &:hover {
        cursor: default;
      }
    }

    &:disabled {
      cursor: default;

      &:active, &.active {
        background-color: $blue800;
        color: $white;
      }
    }

    &.loading {
      background-color: $blue800;

      &:hover, &:active, &.active, &.disabled, &:disabled {
        background-color: $blue800;
      }
    }

    // additional rules if the button is a warning button
    &.warning {
      background-color: $red600;
      &:hover {
        background-color: $red700;
        box-shadow: inset 0 -4px 0 0 $red800;
        @include pointer;
      }

      &:active, &.active {
        background-color: $red800;
        box-shadow: none;
        color: $red50;
      }

      &.disabled {
        background-color: $red100 !important;
        color: $white !important;
        box-shadow: none !important;

        &:hover {
          cursor: default;
        }
      }

      &:disabled {
        cursor: default;

        &:active, &.active {
          background-color: $red800;
          color: $white;
        }
      }

      &.loading {
        background-color: $red800;

        &:hover, &:active, &.active, &.disabled, &:disabled {
          background-color: $red800;
        }
      }
    }
  }

  &.reminder {
    color: $yellow600;
    border: 1px solid $yellow600;
    background-color: $white;
    &:hover {
      &:not(:disabled) {
        color: $yellow700;
        border: 1px solid $yellow700;
        background-color: $yellow200;
      }
    }

    &:active, &.active {
      background-color: $yellow300 !important;
    }

  }

  // special rules in case there is only an icon and no label
  &.iconOnly {
    justify-content: center;
    padding: 0;

    &.large {
      width: 40px;
    }

    &.small {
      width: 32px;
    }

    &.stretch {
      width: 100%;
    }

    .iconWrapper {
      padding-left: 0;
      margin-right: 0;
      margin-left: 0;
    }
  }
}
