@import "../config/includes";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pictureWrapper {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  margin-right: 12px;
}

.noPicture {
  width: 50px;
  height: 50px;
  background: $midnightGrey;
}

.contentWrapper {
  flex: 1 1 auto;
}

.linkWrapper {
  flex: 0 0 auto;
}

.head {
  margin-bottom: 4px;
}

.body {
  display: block;
  line-height: 150%;
}

.linkIcon {
  color: $textColor;
  font-size: 18px;
  margin-left: 12px;

  &:hover {
    color: $blue800;
  }
}

.content {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.picture {
  width: 50px;
  height: 50px;
  object-fit: contain;
  object-position: center;
  background: $grey900;
}

.bold {
  font-weight: 500;
}
