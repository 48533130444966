@import "../config/includes";

.container {
  position: relative;
  overflow: hidden;
}

.leftAlign {
  text-align: left;
}

.rightAlign {
  text-align: right;
}

.centerAlign {
  text-align: center;
}

.wordwrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.table {
  table-layout: fixed;
  border-collapse: collapse;
  @include fontSize13;
  color: $textColor;
}

.tableHeader {
  position: relative;
  z-index: 1;
}

.summaryLine {
  border-top: solid 1px $grey200;
  overflow-x: hidden;
  table {
    position: relative;
    top: -43px;
  }
  thead {
    visibility: hidden;
  }
}

.summaryLineCell {
  padding: 12px;
  @include fontSize13;
  text-align: right;
  font-weight: 600;

  &.summaryLineCellExport {
    height: 40px;
    padding: 0 7px 0 0;
  }
}

/*
 we position the summaryLine absolute in a relative wrapper
 so the content does not have layout and thus cannot break the full table layout
 */
.summaryLineRelativeWrapper {
  position: relative;
}

.summaryLineAbsoluteWrapper {
  position: absolute;
  top: 0;
  white-space: nowrap;

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }

}

.tableBody {
  overflow: auto;

  thead {
    visibility: hidden;
  }
}

.tableRow {
  border-bottom: solid 1px $grey200;

  &.lastTableRow {
    border: none;
  }

  &:hover {
    background-color: $blue50;
  }
}

.tableHeaderRow {
  border-bottom: solid 1px $grey200;
}


.shadow {
  position: absolute;
  transition: opacity 0.15s ease-in-out;
  opacity: 0;

  &.active {
    opacity: 1;
  }


  &.top {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    top: -1px;
    left: 0;
    right: 0;
    height: 43px;
  }

  &.right {
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.12);
    bottom: 0;
    right: -30px;
    top: 0;
    width: 30px;
    z-index: 1;
  }

  &.bottom {
    box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.12);
    bottom: -43px;
    left: 0;
    right: 0;
    height: 43px;

    &.summaryLineOffset {
      bottom: 0;
    }
  }

  &.left {
    box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.12);
    bottom: 0;
    left: -30px;
    top: 0;
    width: 30px;
    z-index: 1;
  }
}

.noData {
  padding: 10px 0;
}

.loadingScreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  opacity: 0.7;
  z-index: 2;
  display: flex;
}
