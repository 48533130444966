/**
 * This file holds all colors belonging to quintly's own color palette. This is not the place to add any additional
 * colors. If you need to add colors outside of quintly's color palette, _colors.scss is the place to do so.
 */

// midnight
$midnight900: #203e49;
$midnight800: #2e4c59;
$midnight700: #3d5a69;
$midnight600: #5c7d8e;
$midnight500: #80a2b3;
$midnight400: #9cb8c6;
$midnight300: #bbced9;
$midnight200: #cedde6;
$midnight100: #e1ecf2;
$midnight50: #f0f6f9;
$midnightGrey: #f5f7f9;

// blue
$blue900: #002355;
$blue800: #003278;
$blue700: #034196;
$blue600: #105bc1;
$blue500: #1b6ddf;
$blue400: #4f8ee6;
$blue300: #75a7eb;
$blue200: #bad3f6;
$blue100: #d2e2f9;
$blue50: #e8f0fe;

// teal
$teal900: #00999c;
$teal800: #13abab;
$teal700: #26beba;
$teal600: #39d0c9;
$teal500: #4ce2d8;
$teal400: #83ede7;
$teal300: #abf6f1;
$teal200: #c1fbf7;
$teal100: #d6fffc;
$teal50: #ebfffe;

// yellow
$yellow900: #391700;
$yellow800: #803300;
$yellow700: #ad4500;
$yellow600: #e05a00;
$yellow500: #ff6600;
$yellow400: #ff7c24;
$yellow300: #ff944d;
$yellow200: #ffb482;
$yellow100: #ffdcc5;
$yellow50: #ffede2;

// red
$red900: #2c0b07;
$red800: #58170e;
$red700: #802114;
$red600: #b92816;
$red500: #d73622;
$red400: #e34735;
$red300: #e37365;
$red200: #eeaca3;
$red100: #f7d7d3;
$red50: #fbebe9;

// green
$green900: #122003;
$green800: #264507;
$green700: #407c04;
$green600: #4a860e;
$green500: #5da812;
$green400: #75b535;
$green300: #8ec25a;
$green200: #bbda9b;
$green100: #dfeed0;
$green50: #f1f8eb;



// grey
$grey900: #31363e;
$grey800: #4d525e;
$grey700: #60656d;
$grey600: #707378;
$grey500: #8c8e92;
$grey400: #c5c5c5;
$grey300: #dddddd;
$grey200: #ececec;
$grey100: #f3f3f3;
$grey50: #fafafa;

$textColor: $grey900;
$primaryActionColor: $blue500;
$mainNavigation: $grey900;
$faceliftOrange: $yellow400;
