@import "config/includes";

.label {
  font-weight: 400;
  white-space: nowrap;
  padding: 0 0 0 5px;
}

.appendix {
  color: $grey400;
}

.wrapper {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
}

.referenceWrapper {
  min-width: 0;
  display: flex;
  flex: 1 1 auto;
}

.divWithEllipsis {
  flex: 1 1 auto;
}
